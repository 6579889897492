import server from "../server/server";

export const checkServer = (cb) => {
    server({
        method: "get",
        url: "/",
    })
        .then((res) => {
            if (res.data.success) {
                cb(res.data.success, null)
            }
        })
        .catch((err) => {
            if (err) {
                cb(null, err);
            }
        });
};