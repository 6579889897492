import { useEffect, useState } from "react";
import Routes from "./routes/Routes";
import Spinner from "./components/spinner";
import { checkServer } from "./stores/checkServer";

const MAX_RETRIES = 3;
const RETRY_DELAY = 5000; // 5 seconds

export default function App() {
  const [isOnline, setIsOnline] = useState(false);
  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    const attemptServerCheck = () => {
      checkServer((data, err) => {
        if (data) {
          setIsOnline(true);
          setLoading(false);
        } else if (retryCount < MAX_RETRIES) {
          setRetryCount(prevCount => prevCount + 1);
          setTimeout(attemptServerCheck, RETRY_DELAY);
        } else {
          setLoading(false);
        }
      });
    };

    attemptServerCheck();
  }, [retryCount]);

  return (
    <div className="text-gray-800">
      {isOnline && !loading ? (
        <Routes />
      ) : (
        <div className="h-screen flex flex-col items-center justify-center">
          <Spinner />
          <p className="mt-4">
            Mohon menunggu, website sedang dipersiapkan
          </p>
          {!loading && !isOnline && (
            <p className="mt-2 text-red-500">
              Gagal terhubung ke server setelah {MAX_RETRIES} percobaan. Silakan hubungi admin.
            </p>
          )}
        </div>
      )}
    </div>
  );
}